import TabsList from "pages/admin/components/Tabs/TabsList";
import { useEffect, useState } from "react";
import useCheckToken from "pages/admin/hooks/useCheckToken";
import AllGoldenSportsRequestsTable from "./golden-sports/allRequestsTable";
import AllSommerCupRequestsTable from "./sommer-cup/allRequestsTable";
const Home = () => {
  const checkToken = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  return (
    <>
      <TabsList
        tabsContainerSx={{
          backgroundColor: "#f9f9f9",
        }}
        tabIndicatorSx={{
          backgroundColor: "black",
        }}
        tabSx={{
          color: "black",
          "&.Mui-selected": {
            color: "black",
            fontWeight: "700",
          },
        }}
        tabs={[
          {
            children: <AllGoldenSportsRequestsTable />,
            title: "Golden Sports",
          },
          {
            children: <AllSommerCupRequestsTable />,
            title: "Sommer Cup",
          },
        ]}
        initialValue={0}
      ></TabsList>
    </>
  );
};
export default Home;
